<template>
    <main class="layout">
        <slot />
    </main>
</template>

<script>
export default {
    props: {},
}
</script>

<style lang="scss" scoped>
.layout {
    @apply flex flex-col mx-auto max-w-[500px] min-h-[100vh] px-[20px] pt-[16px] pb-[40px];
}
</style>
